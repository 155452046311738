var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('material-stats-card',{attrs:{"color":"green","icon":"mdi-package-variant-closed","title":"总数","value":_vm.waitPackage,"sub-icon":"mdi-calendar","sub-text":"待入库包裹"},on:{"click":function($event){return _vm.openPreprocess()}}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('material-stats-card',{attrs:{"color":"green","icon":"mdi-package-variant-closed","title":"总数","value":_vm.waitProcessPackage,"sub-icon":"mdi-calendar","sub-text":"待处理包裹"},on:{"click":function($event){return _vm.openPackageprocess()}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":"","tile":""}},[_c('v-toolbar',[_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" 数据管理 ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.jumpToAllUsers}},[_c('v-list-item-title',[_vm._v("用户列表")])],1),_c('v-list-item',{on:{"click":_vm.jumpToAllRecipients}},[_c('v-list-item-title',[_vm._v("收件人列表")])],1),_c('v-list-item',{on:{"click":_vm.jumpToAllItems}},[_c('v-list-item-title',[_vm._v("物品列表")])],1),_c('v-list-item',{on:{"click":_vm.jumpToRateTable}},[_c('v-list-item-title',[_vm._v("计费标准")])],1),_c('v-list-item',{on:{"click":_vm.jumpToWarehouseList}},[_c('v-list-item-title',[_vm._v("仓库地址列表")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"text":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" 财务管理 ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openChargeDialog()}}},[_c('v-list-item-title',[_vm._v("记录开支")])],1),_c('v-list-item',{on:{"click":_vm.jumpToAllInvoices}},[_c('v-list-item-title',[_vm._v("费用清单")])],1),_c('v-list-item',{on:{"click":_vm.jumpToIncomeDetail}},[_c('v-list-item-title',[_vm._v("数据统计")])],1)],1)],1),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"text":"","outlined":""}},'v-btn',attrs,false),on),[_vm._v(" 文章管理 ")])]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openCreateBlog()}}},[_c('v-list-item-title',[_vm._v("编辑文章")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openBlogList()}}},[_c('v-list-item-title',[_vm._v("文章列表")])],1)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.chargeDialog),callback:function ($$v) {_vm.chargeDialog=$$v},expression:"chargeDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("记录开支")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.chargeTypeList,"label":"类型"},model:{value:(_vm.chargeType),callback:function ($$v) {_vm.chargeType=$$v},expression:"chargeType"}}),_c('v-text-field',{attrs:{"label":"备注","required":""},model:{value:(_vm.trackingNm),callback:function ($$v) {_vm.trackingNm=$$v},expression:"trackingNm"}}),_c('v-text-field',{attrs:{"label":"总额","required":""},model:{value:(_vm.chargeAmount),callback:function ($$v) {_vm.chargeAmount=$$v},expression:"chargeAmount"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.chargeSure}},[_vm._v(" 确认 ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarColor,"timeout":3000,"top":"","dark":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"white"}},[_vm._v(" mdi-bell-plus ")]),_vm._v(" "+_vm._s(_vm.notification)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v(" mdi-close-circle ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }